<template>
  <div>
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные для личного кабинета</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <UserInfoForClient
        v-on:change-role="onRoleChanged"
        ref="clientInfoForUser"
      />
    </div>

    <ButtonsHolder :callBack="handleAcrtionClientData" />

    <ClientUsersList></ClientUsersList>
  </div>
</template>

<script>
import ClientUsersList from "@/components/clients/ClientUsersList"
import UserInfoForClient from "@/components/client-users/UserInfoForClient"
import ButtonsHolder from "@/components/clients/ButtonsHolder"
import { mapActions, mapGetters } from "vuex"
//import { API_PREFIX } from "@/services/api/v1/const"
export default {
  name: "CreateNewClientUser",
  data: function () {
    return {
      fieldRole: "DEALER_ADMIN",
    }
  },
  mounted() {},
  components: {
    ButtonsHolder,
    UserInfoForClient,
    ClientUsersList,
  },
  computed: mapGetters({
    userRole: "auth/userRole",
    user: "auth/user",
  }),
  methods: {
    ...mapActions({
      createDealerUser: "clients/createClientUser",
    }),
    checkClient() {
      this.$refs.clientDataComponent.$v.fields.$touch()
      this.$refs.clientInfoForUser.$v.fields.$touch()
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    async handleAcrtionClientData() {
      await this.addNewClient()
    },
    async addNewClient() {
      const [login, password, email] =
        this.$refs.clientInfoForUser.$v.fields.$model

      try {
        await this.createDealerUser({
          user: {
            name: login.value,
            email: email.value,
            password: password.value,
            role: this.fieldRole,
          },
        })

        this.onUserCreated()
      } catch (error) {
        if (error.data.errors) {
          let errors = error.data.errors
          if (errors.name) this.$message.error("Укажите имя пользователя")
          if (errors.password)
            this.$message.error(
              "Пароль должен содержать не менее 6 символов, обязательно наличие Букв латиницей и хотя бы одной цифры"
            )
          if (errors.email) this.$message.error("Укажите правильный email")
        }
      }
    },
    onRoleChanged(value) {
      this.fieldRole = value
    },
    onUserCreated() {
      const vm = this
      const h = this.$createElement
      this.$info({
        title: "",
        content: h("div", {}, [h("p", "Пользователь успешно добавлен!")]),
        onOk() {
          vm.$router.go() //перезагрузка страницы
        },
      })
    },
  },
}
</script>

<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.tabsinfo {
  min-height: 570px;
}
.buttonsholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 auto;
}
.selects {
  width: 200px;
}
.optGroupSelect {
  margin-top: 10px;
}
.newClientHeader {
  font-weight: bold;
}
.ant-collapse-header {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>
<style lang="scss" scoped>
.divider {
  margin-bottom: -30px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
</style>
