<template>
  <div style="width: 100%">
    <div class="content-container">
      <a-table
        bordered
        :row-selection="{
          fixed: false,
          hideDefaultSelections: true,
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columnsList"
        :data-source="clientUsers"
        :customRow="customRow"
        :rowKey="row => row.id"
        class="ordertable"
        @change="onChange"
        :pagination="false"
      >
        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            title="Уверены, что хотите удалить пользователя?"
            ok-text="Удалить"
            cancel-text="Отмена"
            placement="topLeft"
            @confirm="event => onDelete(event, record.id)"
          >
            <a-button
              type="danger"
              ghost
              shape="circle"
              icon="delete"
              size="small"
              @click="onDelete"
            />
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
function onChange(pagination, filters, sorter) {
  console.log("params", pagination, filters, sorter)
}

export default {
  props: {
    onlyList: {
      type: Boolean,
      default: () => false,
    },
  },
  mounted() {
    this.fetchClientUsers()
  },
  computed: {
    ...mapGetters({
      columns: "clients/columnsClientUsers",
      clientUsers: "clients/clientUsers",
    }),

    columnsList() {
      if (!this.isOwner) {
        return this.columns
      }

      const list = [...this.columns]
      list.pop()
      return list
    },
  },
  data() {
    return {
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "advanced_search" }),
    }
  },
  methods: {
    onChange,
    ...mapMutations({}),
    ...mapActions({
      deleteClientUser: "clients/deleteClientUser",
      fetchClientUsers: "clients/fetchClientUsers",
    }),
    onPaymentGroupChange(value) {
      this.form.setFieldsValue({
        paymentGroup: value,
      })
    },
    onDelete(event, key) {
      event.stopPropagation()
      if (key || key === 0) {
        this.deleteClientUser(key)
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleReset() {},
    customRow(record) {
      return {
        on: {
          click: () => {
            //this.$router.push(`/clientUser/${record.id}`)
            console.info("clicked: ", `/clientUser/${record.id}`)
          },
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}
.top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 -5px;
  > button {
    &:first-child {
      margin-right: 10px;
    }
  }
}
.filterFields {
  display: flex;
  flex-direction: row;
}
.filterFormItems {
  display: block;
  margin-right: 20px;
}
.selects {
  width: 200px;
}
</style>

<style>
th.column-action,
td.column-action {
  text-align: center !important;
}
</style>
