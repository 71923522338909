<template>
  <div>
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }">
      <a-row :gutter="12">
        <template v-for="(v, index) in $v.fields.$each.$iter">
          <a-col :key="v.$model.id" class="form-item">
            <a-form-item
              :has-feedback="v.value.minLength"
              :validate-status="v.value.$error ? 'error' : ''"
              :label="v.$model.name"
              :help="v.value.$error && 'Необходимо заполнить поле'"
            >
              <a-input
                v-model.trim="v.value.$model"
                :placeholder="v.$model.placeholder"
              >
                <a-tooltip
                  v-if="v.$model.createclientname === 'password'"
                  slot="suffix"
                  title="Пароль должен содержать не менее 6 символов, обязательно наличие Букв латиницей и хотя бы одной цифры"
                >
                  <a-icon
                    type="info-circle"
                    style="color: rgba(0, 0, 0, 0.45)"
                  />
                </a-tooltip>
              </a-input>
            </a-form-item>

            <a-form-item v-show="index > 1" label="Роль">
              <a-select
                default-value="DEALER_ADMIN"
                placeholder="Выберите роль"
                @change="handleRoleSelectChange"
              >
                <a-select-option value="DEALER_ADMIN">
                  Администратор Дилера
                </a-select-option>

                <a-select-option value="DEALER_OPERATOR">
                  Пользователь дилера
                </a-select-option>

                <!--                <a-select-option value="DEALER_ANALYST">-->
                <!--                  Аналитик Дилера-->
                <!--                </a-select-option>-->

                <!--                <a-select-option value="DEALER_ORDER_OPERATOR">-->
                <!--                  Оператор заказов-->
                <!--                </a-select-option>-->

                <!--                <a-select-option value="DEALER_OPERATOR_LOYALTY">-->
                <!--                  Оператор программы лояльности-->
                <!--                </a-select-option>-->
              </a-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  name: "UserInfoForDealer",
  data: () => ({
    fields: [
      {
        id: 1,
        name: "Имя пользователя",
        placeholder: "Введите имя пользователя",
        createclientname: "login",
        value: "",
      },
      {
        id: 2,
        name: "Пароль",
        placeholder: "Введите пароль",
        createclientname: "password",
        value: "",
      },
      {
        id: 3,
        name: "Почта",
        placeholder: "Введите почту",
        createclientname: "mail",
        value: "",
      },
    ],

    fieldRole: "MANUFACTURER_ADMIN",
  }),
  validations: {
    fields: {
      required,
      $each: {
        value: {
          required,
        },
      },
    },
  },
  methods: {
    handleRoleSelectChange(value) {
      this.fieldRole = value
      this.$emit("change-role", value)
    },
  },
}
</script>

<style scoped></style>
